@tailwind base;


@font-face {
  font-family: 'Oxygen';
  font-weight: 700;
  font-style: normal;
  src: local('Oxygen Bold'), url(../public/fonts/Oxygen/Oxygen-Bold.ttf) format("truetype");
}
@font-face {
  font-family: 'Oxygen';
  font-weight: 300;
  font-style: normal;
  src: local('Oxygen Light'), url(../public/fonts/Oxygen/Oxygen-Light.ttf) format("truetype");
}
@font-face {
  font-family: 'Oxygen';
  font-weight: 400;
  font-style: normal;
  src: local('Oxygen Regular'), url(../public/fonts/Oxygen/Oxygen-Regular.ttf) format("truetype");
}
@font-face {
  font-family: 'Rajdhani';
  font-weight: 700;
  font-style: normal;
  src: local('Rajdhani Bold'), url(../public/fonts/Rajdhani/Rajdhani-Bold.ttf) format("truetype");
}
@font-face {
  font-family: 'Rajdhani';
  font-weight: 300;
  font-style: normal;
  src: local('Rajdhani Light'), url(../public/fonts/Rajdhani/Rajdhani-Light.ttf) format("truetype");
}
@font-face {
  font-family: 'Rajdhani';
  font-weight: 400;
  font-style: normal;
  src: local('Rajdhani Regular'), url(../public/fonts/Rajdhani/Rajdhani-Regular.ttf) format("truetype");
}
@font-face {
  font-family: 'Rajdhani';
  font-weight: 500;
  font-style: normal;
  src: local('Rajdhani Medium'), url(../public/fonts/Rajdhani/Rajdhani-Medium.ttf) format("truetype");
}
@font-face {
  font-family: 'Rajdhani';
  font-weight: 600;
  font-style: normal;
  src: local('Rajdhani Semibold'), url(../public/fonts/Rajdhani/Rajdhani-SemiBold.ttf) format("truetype");
}

@tailwind components;
@tailwind utilities;

body {
  height: 600vh;
  background: #000;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}

body {
  -ms-overflow-style:none;
}

.sequence-wrap {
  position: relative;
  height: 200vh;
}

.normal-scroll-content {
  padding: 20px;
  height: 200vh;
  position: absolute;
  transform: translate(-50%, -50%); /* Offset by 50% of its own width and height */
  top: 50%; /* Center vertically */
  left: 50%; 
}

.sequence-wrap {
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000; /* Background color for visibility */
}

.png-sequence {
  width: 100%;
  height: 100%;
  display: block;
}
.scrollable-place {
  height: 1000px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.track {
  height: 500vh;
}
